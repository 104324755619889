// Here you can add other styles

@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
body,
.table {
  font-family: 'Tajawal', sans-serif;
}

// main area background color
.bg-light {
  background-color: #f0f4f7 !important;
}
// sidebar active nav link
.sidebar-nav .nav-link.active {
  background: #247ba0 !important;
}
// link styles
a {
  color: #3899ec;
}

// scrollbar style
::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: #cad9b2; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #5b7553;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
// customise datepicker clear button
.react-datepicker__close-icon {position:static !important;}
.Master-Layout-Body{
    height: auto;
}

.Header{
    height: 30px;
}
.headerS{
  text-align: center;
  margin-right: 250px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.pagination {
    display: inline-block;
  }

  .pagination button {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
  }
  .pagination button.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }

  .pagination button:hover:not(.active) {background-color: #ddd;}
  .exit-button{
    margin-left: 15px;
  }
  .exit-button.hover{
    background-color: red;
  }

  input[type='number']{ line-height: 28px; }

  .scrollable{
    max-width: 100%;
    overflow-x: scroll;
  }

  .select{
    width: 150px;
  }

  .bodywidth{
    width: auto;
  }

  .editableTable {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    background-color: transparent;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        width: auto;
        max-width: 100%;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }


//   .dropdown-item {
// background-color: #4CAF50;
// }
// :onclick
.dropdown-item.active, .dropdown-item:active {
  color: var(--cui-dropdown-link-active-color);
  text-decoration: none;
  background-color: #41bd3d;
}


// navbar
.dropdown-submenu-container {
  position: relative;
}
.dropdown-submenu-container a.dropdown-submenu::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}
.dropdown-submenu-container .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 1px;
  margin-right: auto;
}
.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: 1px;
}
a{
  text-decoration: none;
  color: #000000;
}


.customModal{
  border:none;
// background-color: #000000;

opacity: 1;
}

.modal-content{
  border:none;
  // opacity: 1;

}
